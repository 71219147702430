import React, { Component } from 'react'
import '../styles/mapa.css'
import ContenedorMapa from './ContenedorMapa'

class MapaInteractivo extends Component {

  render() {
    return <ContenedorMapa />
  }
}

export default MapaInteractivo
