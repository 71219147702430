import React from 'react'
import NAIE from '../../../styles/images/torres/NAI/NAI.png'

const NAISvg = props => {
  const handleHoverRen = ed => {
    let render = null
    let text = null
    switch (ed) {
      case 'N2B2':
        render = document.getElementById('N2B2')
        text = document.getElementById('N2B2T')
        break
      case 'N1B':
        render = document.getElementById('N1B')
        text = document.getElementById('N1BT')
        break
      case 'PBA':
        render = document.getElementById('PBA')
        text = document.getElementById('PBAT')
        break
      case 'N3CRG':
        render = document.getElementById('N3CRG')
        text = document.getElementById('N3CRGT')
        break
      case 'N3C1RG':
        render = document.getElementById('N3C1RG')
        text = document.getElementById('N3C1RGT')

        break
      case 'N2B3':
        render = document.getElementById('N2B3')
        text = document.getElementById('N2B3T')

        break
      case 'N1B1':
        render = document.getElementById('N1B1')
        text = document.getElementById('N1B1T')

        break
      case 'PBA1':
        render = document.getElementById('PBA1')
        text = document.getElementById('PBA1T')

        break
      default:
        break
    }

    render.classList.add('activeRender')
    text.classList.add('activeText')
  }

  const handleRemoveRen = ed => {
    let render = null
    let text = null
    switch (ed) {
      case 'N2B2':
        render = document.getElementById('N2B2')
        text = document.getElementById('N2B2T')
        break
      case 'N1B':
        render = document.getElementById('N1B')
        text = document.getElementById('N1BT')
        break
      case 'PBA':
        render = document.getElementById('PBA')
        text = document.getElementById('PBAT')
        break
      case 'N3CRG':
        render = document.getElementById('N3CRG')
        text = document.getElementById('N3CRGT')
        break
      case 'N3C1RG':
        render = document.getElementById('N3C1RG')
        text = document.getElementById('N3C1RGT')

        break
      case 'N2B3':
        render = document.getElementById('N2B3')
        text = document.getElementById('N2B3T')

        break
      case 'N1B1':
        render = document.getElementById('N1B1')
        text = document.getElementById('N1B1T')

        break
      case 'PBA1':
        render = document.getElementById('PBA1')
        text = document.getElementById('PBA1T')

        break
      default:
        break
    }

    render.classList.remove('activeRender')
    text.classList.remove('activeText')
  }

  return (
    <div>
      <img src={NAIE} className="torre-render" />
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1306 734"
        xmlSpace="preserve"
        className="azul"
        onClick={props.onClick}
      >
        <g id="NAI_1_">
          <g id="NAI">
            <polygon
              className="st0"
              id="N2B3"
              onMouseOver={e => handleHoverRen('N2B3')}
              onMouseLeave={e => handleRemoveRen('N2B3')}
              points="965.6,336.5 802.6,312.5 791.6,319.5 791.6,332.5 767.6,347.5 689.6,336.5 689.6,328.5 555.6,308.5 
			555.6,384.5 688.6,407.5 688.6,423.5 763.6,437.5 788.6,418.5 788.6,404.5 798.6,397.5 798.6,390.5 959.6,417.5 1018.7,355.6 
			1020.6,322.5 1130.6,218.5 1132.6,168.5 1032.6,249.6 1032.6,278.5 		"
            />
            <polyline
              className="st0"
              id="N1B1"
              onMouseOver={e => handleHoverRen('N1B1')}
              onMouseLeave={e => handleRemoveRen('N1B1')}
              points="1014.6,433.5 953.6,503.5 796.6,472.5 796.6,479.5 786.6,488.5 786.6,502.5 762.6,524.5 
			688.6,508.5 688.6,493.5 557.6,466.5 555.6,392.5 688.6,415.5 688.6,423.5 763.6,437.5 788.6,418.5 788.6,404.5 798.6,397.5 
			959.6,425.5 1020.6,361.5 1020.6,355.6 1018.7,355.6 1019.6,329.5 1132.6,222.5 1130.6,275.5 1015.6,399.5 1014.6,433.5 		"
            />
            <polyline
              className="st0"
              id="PBA1"
              onMouseOver={e => handleHoverRen('PBA1')}
              onMouseLeave={e => handleRemoveRen('PBA1')}
              points="1014.6,433.5 1014.6,440.5 953.6,510.5 796.6,479.5 786.6,488.5 786.6,502.5 762.6,524.5 
			688.6,508.5 688.6,500.5 558.6,473.5 559.6,512.5 479.6,567.5 479.6,570.8 496.6,575.5 496.6,615.5 485.6,624.5 485.6,631.5 
			794.6,710.5 859.6,626.5 846.6,622.5 847.6,582.5 863.6,563.5 946.6,580.5 1009.6,502.5 1009.6,468.1 1130.6,326.5 1132.6,279.5 
			1015.6,406.5 1014.6,433.5 		"
            />
            <polygon
              className="st0"
              id="N3C1RG"
              onMouseOver={e => handleHoverRen('N3C1RG')}
              onMouseLeave={e => handleRemoveRen('N3C1RG')}
              points="1132.6,94.5 975.6,83.5 962.6,89.5 964.6,57.5 786.6,45.5 621.6,101.5 620.6,189.5 554.6,221.5 
			554.6,300.5 689.6,320.5 689.6,336.5 767.6,347.5 791.6,332.5 791.6,319.5 802.6,312.5 802.6,305.5 965.6,328.5 1025.6,276.5 
			1032.6,271.5 1032.6,243.5 1130.6,163.5 		"
            />
            <polygon
              className="st0"
              id="N3CRG"
              onMouseOver={e => handleHoverRen('N3CRG')}
              onMouseLeave={e => handleRemoveRen('N3CRG')}

              points="650.6,37.5 484.6,74.5 484.6,80.9 462.6,87.5 462.6,92 486.4,92 486.6,106.5 301.6,157.5 
			301.6,166.7 305.6,233.5 345.6,240.5 345.6,255.5 345.6,282.5 407.6,291.5 420.6,286.5 420.6,279.5 546.6,298.6 546.6,220.3 
			613.6,188.5 614.6,101.5 778.8,45.5 		"
            />
            <polygon
              className="st0"
              id="N2B2"
              onMouseOver={e => handleHoverRen('N2B2')}
              onMouseLeave={e => handleRemoveRen('N2B2')}
              points="549.6,380.7 548.6,307.5 419.6,286.9 407.6,291.5 345.6,282.5 345.6,247.5 308.6,241.5 310.6,307.5 
			370.6,317.5 352.6,325.5 352.6,333.5 350.6,334.5 351.6,361.5 411.2,373.5 424.6,367.5 424.6,358.5 		"
            />
            <polygon
              className="st0"
              id="N1B"
              onMouseOver={e => handleHoverRen('N1B')}
              onMouseLeave={e => handleRemoveRen('N1B')}

              points="314.6,315.5 315.6,378.5 381.6,391.5 357.6,403.5 357.6,412.5 355.9,412.5 356.1,437.5 415.1,451 
			425.1,446 425.1,439.4 429.1,437.5 551.1,463 549.6,390.5 424.6,367.5 411.2,373.5 351.6,361.5 350.6,334.5 352.6,333.5 
			352.6,325.5 359.4,322.5 		"
            />
            <polygon
              className="st0"
              id="PBA"
              onMouseOver={e => handleHoverRen('PBA')}
              onMouseLeave={e => handleRemoveRen('PBA')}

              points="315.6,385.5 315.6,444.5 272.6,433.5 160.1,488 160.1,492.3 177.1,498 179.1,536 169.1,540 
			169.1,548 413.1,617 506.1,554 506.1,544.1 492.1,539 492.1,529 551.1,543.2 551.1,472 425.1,446 415.1,451 356.1,437.5 
			355.9,412.5 357.6,412.5 357.6,403.5 372.5,396.1 		"
            />
            <text
              transform="matrix(1 0 0 1 266.8872 518.0927)"
              className="st1 st2"
              id="PBAT"
              onMouseOver={e => handleHoverRen('PBA')}
              onMouseLeave={e => handleRemoveRen('PBA')}
            >
              <tspan x="0" y="0" id="PBAT">
                UNIDAD 8101
              </tspan>
              <tspan x="28.9" y="21.6" id="PBAT" className="sold">
                {/* 573 M2 */}
                Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 387.9303 405.0044)"
              className="st1 st2"
              id="N1BT"
              onMouseOver={e => handleHoverRen('N1B')}
              onMouseLeave={e => handleRemoveRen('N1B')}
            >
              <tspan x="0" y="0" id="N1BT">
                UNIDAD 8201
              </tspan>
              <tspan x="28.9" y="21.6" id="N1BT" className="sold">
                {/* 491 M2 */}
                Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 393.3162 185.7294)"
              className="st1 st2"
              id="N3CRGT"
              onMouseOver={e => handleHoverRen('N3CRG')}
              onMouseLeave={e => handleRemoveRen('N3CRG')}
            >
              <tspan x="0" y="0" id="N3CRGT">
                UNIDAD 8401
              </tspan>
              <tspan x="28.9" y="21.6" id="N3CRGT" className="sold">
                {/* 620 M2 */}
                Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 605.3834 589.9069)"
              className="st1 st2"
              id="PBA1T"
              onMouseOver={e => handleHoverRen('PBA1')}
              onMouseLeave={e => handleRemoveRen('PBA1')}
            >
              <tspan x="0" y="0" id="PBA1T">
                UNIDAD 8102
              </tspan>
              <tspan x="28.9" y="21.6" id="PBA1T" className="sold">
                {/* 573 M2 */}
                Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 642.7177 461.1655)"
              className="st1 st2"

              id="N1B1T"
              onMouseOver={e => handleHoverRen('N1B1')}
              onMouseLeave={e => handleRemoveRen('N1B1')}
            >
              <tspan x="0" y="0" id="N1B1T">
                UNIDAD 8202
              </tspan>
              <tspan x="28.9" y="21.6" id="N1B1T" className="sold">
                {/* 491 M2 */}
                Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 660.9645 218.3807)"
              className="st1 st2"
              id="N3C1RGT"
              onMouseOver={e => handleHoverRen('N3C1RG')}
              onMouseLeave={e => handleRemoveRen('N3C1RG')}
            >
              <tspan x="0" y="0" id="N3C1RGT">
                UNIDAD 8402
              </tspan>
              <tspan x="28.9" y="21.6" id="N3C1RGT" className="sold">
                {/* 620 M2 */}
                Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 380.4567 325.1082)"
              className="st1 st2"
              id="N2B2T"
              onMouseOver={e => handleHoverRen('N2B2')}
              onMouseLeave={e => handleRemoveRen('N2B2')}
            >
              <tspan x="0" y="0" id="N2B2T">
                UNIDAD 8301
              </tspan>
              <tspan x="28.9" y="21.6" id="N2B2T" className="sold">
                {/* 491 M2 */}
                Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 665.244 379.2693)"
              className="st1 st2"
              id="N2B3T"
              onMouseOver={e => handleHoverRen('N2B3')}
              onMouseLeave={e => handleRemoveRen('N2B3')}

            >
              <tspan x="0" y="0" id="N2B3T">
                UNIDAD 8302
              </tspan>
              <tspan x="28.9" y="21.6" id="N2B3T" className="sold">
                {/* 491 M2 */}
                Vendida
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default NAISvg
