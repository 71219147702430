import React from 'react'
import AVEE from '../../../styles/images/torres/AVE/AVE.png'

const AVESvg = props => {
  const handleHoverRen = ed => {
    let render = null
    let text = null
    switch (ed) {
      case 'PBA':
        render = document.getElementById('PBA')
        text = document.getElementById('PBAT')
        break
      case 'N1B':
        render = document.getElementById('N1B')
        text = document.getElementById('N1BT')
        break
      case 'N2B2':
        render = document.getElementById('N2B2')
        text = document.getElementById('N2B2T')
        break

      case 'N3CRG':
        render = document.getElementById('N3CRG')
        text = document.getElementById('N3CRGT')
        break
      case 'PBA1':
        render = document.getElementById('PBA1')
        text = document.getElementById('PBA1T')
        break
      case 'N1B1':
        render = document.getElementById('N1B1')
        text = document.getElementById('N1B1T')

        break
      case 'N2B3':
        render = document.getElementById('N2B3')
        text = document.getElementById('N2B3T')

        break
      case 'N3C1':
        render = document.getElementById('N3C1')
        text = document.getElementById('N3C1T')
        break
      default:
        break
    }

    render.classList.add('activeRender')
    text.classList.add('activeText')
  }

  const handleRemoveRen = ed => {
    let render = null
    let text = null
    switch (ed) {
      case 'PBA':
        render = document.getElementById('PBA')
        text = document.getElementById('PBAT')
        break
      case 'N1B':
        render = document.getElementById('N1B')
        text = document.getElementById('N1BT')
        break
      case 'N2B2':
        render = document.getElementById('N2B2')
        text = document.getElementById('N2B2T')
        break
      case 'N3CRG':
        render = document.getElementById('N3CRG')
        text = document.getElementById('N3CRGT')
        break
      case 'PBA1':
        render = document.getElementById('PBA1')
        text = document.getElementById('PBA1T')

        break
      case 'N1B1':
        render = document.getElementById('N1B1')
        text = document.getElementById('N1B1T')

        break
      case 'N2B3':
        render = document.getElementById('N2B3')
        text = document.getElementById('N2B3T')

        break
      case 'N3C1':
        render = document.getElementById('N3C1')
        text = document.getElementById('N3C1T')

        break
      default:
        break
    }

    render.classList.remove('activeRender')
    text.classList.remove('activeText')
  }

  return (
    <div>
      <img src={AVEE} className="torre-render" />
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1306 734"
        xmlSpace="preserve"
        className="verde"
        onClick={props.onClick}
      >
        <g id="AVE_1_">
          <g id="AVE">
            <polygon
              className="st0"
              id="PBA1"
              onMouseOver={e => handleHoverRen('PBA1')}
              onMouseLeave={e => handleRemoveRen('PBA1')}
              points="955.2,496.3 980.9,468.9 980.4,434 1044.9,371.8 1040.5,424.6 976.4,496.3 974,537.4 925.5,594.7
			794.3,560.5 778.2,574.3 778.2,594.7 778.2,636 785.9,637.7 733.6,697.5 525.9,644.3 525.9,640 534,633.1 533,584.2 530.9,583.8
			530.9,580 555,560.5 555,500 677.1,525 677.1,533.2 752.8,548.9 777.1,528.4 777.3,510.9 794.3,496.3 931,523.9 		"
            />
            <polygon
              className="st0"
              id="N1B1"
              onMouseOver={e => handleHoverRen('N1B1')}
              onMouseLeave={e => handleRemoveRen('N1B1')}
              points="552.9,416.8 677.9,439 677.9,446.2 755,460.1 780,441 780,425.3 796.7,415.3 935.9,438.1 986,389.1
			986,347.9 1049.3,304.4 1044.9,363.4 980.4,423.5 980.5,462 931,514.5 794.3,489.4 778.1,503.8 777.1,528.4 753.4,549.9
			677.1,533 677.1,516 555,491.8 		"
            />
            <polygon
              className="st0"
              id="N2B3"
              onMouseOver={e => handleHoverRen('N2B3')}
              onMouseLeave={e => handleRemoveRen('N2B3')}
              points="555,331.1 677.9,349.4 677.9,356.1 757,367.9 781.9,351.5 781.9,338.7 798.5,328.6 941,349.4
			991.4,306.6 991.4,266.3 1085.9,195.5 1080.6,282.2 986,347.9 986.1,382.2 935.9,429.3 796.7,406.4 780,416.8 780,441 755,460
			677.9,445.7 677.9,429.9 555,408 		"
            />
            <polygon
              className="st0"
              id="N3C1"
              onMouseOver={e => handleHoverRen('N3C1')}
              onMouseLeave={e => handleRemoveRen('N3C1')}
              points="1085.9,195.5 991.4,266.3 991.4,300.3 940.8,340.7 798.8,320.5 782,330.8 781.9,351.5 757,367.9
			677.9,356.1 677.9,349.4 677.9,340.7 555,322.7 553.9,239.6 601.5,214.6 601.6,122 775.4,69.8 939.2,80.3 937.1,129.2
			1065.9,139.6 1065.9,145.5 1095.7,146.9 1095.7,153.5 1089.2,158.2 1087.8,193.5 		"
            />
            <polygon
              className="st0"
              id="PBA"
              onMouseOver={e => handleHoverRen('PBA')}
              onMouseLeave={e => handleRemoveRen('PBA')}
              points="548.8,565.1 475,551.3 475,565.1 482.1,567.5 482.1,570.5 415.9,617.2 244.7,575.7 244.7,568
			252.9,563.8 250.4,517.8 250,511.4 321.9,473.3 402.4,492.5 402.4,483.4 359.9,475.3 360.1,470.9 356.9,470.4 356.9,466
			418.7,478.8 428.1,473.3 548.2,498 		"
            />
            <polygon
              className="st0"
              id="N1B"
              onMouseOver={e => handleHoverRen('N1B')}
              onMouseLeave={e => handleRemoveRen('N1B')}
              points="547,415.3 547,489 428.1,466 428.1,473.3 418.7,478.8 356.9,466 356.1,439 358.9,437.4 358.9,424.6
			373.5,416.8 338,410 338,403.4 339.3,402.8 339.3,371.8 351,363.3 351,385.8 415.9,398.2 424.1,392.8 		"
            />
            <polygon
              className="st0"
              id="N2B2"
              onMouseOver={e => handleHoverRen('N2B2')}
              onMouseLeave={e => handleRemoveRen('N2B2')}
              points="544.9,329 545.8,405.7 424.1,385 424.1,392.8 415.9,398.2 351,385.8 351,359.1 353.9,357.5
			353.9,344.4 360.9,340.7 334.1,337.2 334.1,329 335.3,329 334.1,298.7 347,293.4 347,303.6 411,313.5 420.1,309.1 		"
            />
            <polygon
              className="st0"
              id="N3CRG"
              onMouseOver={e => handleHoverRen('N3CRG')}
              onMouseLeave={e => handleRemoveRen('N3CRG')}
              points="544,319.7 420,302.4 420.1,309.1 411,313.5 347,303.6 347,275.4 349.1,274.4 349,264.9 329,262
			329,255 329.9,255 329.9,223 372,211.5 372,190 324.1,184.8 324.1,177.9 377.1,163.8 377,159.9 470,134.6 470,113.5 441,111
			441,107.1 470,100.3 470,95.2 624.8,59.5 769.1,68.9 595.9,121.9 595.9,211.5 548.9,232.1 548.9,237.6 542.9,241.8 		"
            />
            <text
              transform="matrix(1 0 0 1 316.4879 532.5748)"
              className="st1 st2"
              id="PBAT"
              onMouseOver={e => handleHoverRen('PBA')}
              onMouseLeave={e => handleRemoveRen('PBA')}
            >
              <tspan x="0" y="0" id="PBAT">
                UNIDAD 3101
              </tspan>
              <tspan x="28.9" y="21.6" id="PBAT"  className="sold">
		      {/*367 M2*/}
			Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 387.5311 438.4864)"
              className="st1 st2"
              onMouseOver={e => handleHoverRen('N1B')}
              onMouseLeave={e => handleRemoveRen('N1B')}
              id="N1BT"
            >
              <tspan x="0" y="0" id="N1BT">
                UNIDAD 3201
              </tspan>
              <tspan x="28.9" y="21.6" id="N1BT" className="sold">
                {/*327 M2*/}
                Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 392.917 230.2115)"
              className="st1 st2"
              id="N3CRGT"
              onMouseOver={e => handleHoverRen('N3CRG')}
              onMouseLeave={e => handleRemoveRen('N3CRG')}
            >
              <tspan x="0" y="0" id="N3CRGT">
                UNIDAD 3401
              </tspan>
              <tspan x="28.9" y="21.6" id="N3CRGT" className="sold">
                {/* 436 M2 */}
                Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 604.9841 594.389)"
              className="st1 st2"
              id="PBA1T"
              onMouseOver={e => handleHoverRen('PBA1')}
              onMouseLeave={e => handleRemoveRen('PBA1')}
            >
              <tspan x="0" y="0" id="PBA1T">
                UNIDAD 3102
              </tspan>
              <tspan x="28.9" y="21.6" id="PBA1T"  className="sold">
		      {/*367 M2*/}
			Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 642.3184 486.6476)"
              className="st1 st2"
              id="N1B1T"
              onMouseOver={e => handleHoverRen('N1B1')}
              onMouseLeave={e => handleRemoveRen('N1B1')}
            >
              <tspan x="0" y="0" id="N1B1T">
                UNIDAD 3202
              </tspan>
              <tspan x="28.9" y="21.6" id="N1B1T" className="sold">
		      {/*327 M2*/}
			Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 660.5652 292.8628)"
              className="st1 st2"
              id="N3C1T"
              onMouseOver={e => handleHoverRen('N3C1')}
              onMouseLeave={e => handleRemoveRen('N3C1')}
            >
              <tspan x="0" y="0" id="N3C1T">
                UNIDAD 3402
              </tspan>
              <tspan x="28.9" y="21.6" id="N3C1T" className="sold">
                {/*436 M2*/}
                Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 380.0574 355.5902)"
              className="st1 st2"
              id="N2B2T"
              onMouseOver={e => handleHoverRen('N2B2')}
              onMouseLeave={e => handleRemoveRen('N2B2')}
            >
              <tspan x="0" y="0" id="N2B2T">
                UNIDAD 3301
              </tspan>
              <tspan x="28.9" y="21.6" id="N2B2T"  className="sold">
		      {/*327 M2*/}
			Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 664.8447 403.7514)"
              className="st1 st2"
              id="N2B3T"
              onMouseOver={e => handleHoverRen('N2B3')}
              onMouseLeave={e => handleRemoveRen('N2B3')}
            >
              <tspan x="0" y="0" id="N2B3T">
                UNIDAD 3302
              </tspan>
              <tspan x="28.9" y="21.6" id="N2B3T"  className="sold" >
		      {/*327 M2*/}
			Vendida
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default AVESvg
