import React, { useState } from 'react'
import ECA from './edificios/ECA/ECA'
import IMA from './edificios/IMA/IMA'
import AVE from './edificios/AVE/AVE'
import AURA from './edificios/AURA/AURA'
import QU from './edificios/QU/QU'
import YUA from './edificios/YUA/YUA'
import HAA from './edificios/HAA/HAA'
import NAI from './edificios/NAI/NAI'

import RenderInicial from './RenderInicial'
import '../styles/mapa.css'

class ContenedorMapa extends React.Component {
  state = {
    position: 0,
    edificio: '',
    planoIsActive: 0
  }

  handlerClick = e => {
    this.setState({ edificio: e.target.id, position: 1 })
  }

  planoActive = () => {
    this.setState({
      planoIsActive: 1,
    })
  }

  determinatePosition = () => {
    switch (this.state.position) {
      case 0:
        return <RenderInicial onClick={this.handlerClick} />
      case 1:
        switch (this.state.edificio) {
          case 'IMA':
            return <IMA position={this.state.position} planoActive={this.planoActive} />
          case 'AVE':
            return <AVE position={this.state.position} planoActive={this.planoActive} />
          case 'ECA':
            return <ECA position={this.state.position} planoActive={this.planoActive} />
          case 'HAA':
            return <HAA position={this.state.position} planoActive={this.planoActive} />
          case 'NAI':
            return <NAI position={this.state.position} planoActive={this.planoActive} />
          case 'QU':
            return <QU position={this.state.position} planoActive={this.planoActive} />
          case 'YUA':
            return <YUA position={this.state.position} planoActive={this.planoActive} />
          case 'AURA':
            return <AURA position={this.state.position} planoActive={this.planoActive} />
          default:
            break
        }
      default:
        break
    }
  }

  determinarTitle = () => {
    if (this.state.position === 0) {
      return 'MASTER PLAN'
    }
    if (this.state.position === 1) {
      return this.state.edificio
    }
  }

  determinarSubtitle = () => {
    if (this.state.position === 0) {
      return 'Da click a una torre para conocer las residencias de naya'
    }
    if (this.state.position === 1) {
      return 'SELECCIÓNA UN PISO'
    }
  }

  handlerClickArrow = e => {
    e.preventDefault()
    this.setState({ position: this.state.position - 1 })
  }

  render() {
    return (
      <div className="MapaInteractivo">
        <RenderInicial onClick={this.handlerClick} />
      </div>
    )
  }
}

export default ContenedorMapa
