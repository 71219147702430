import React, { useState } from 'react'
import TweenLite from 'gsap/all'
import '../../../styles/mapa.css'
import rosa from '../../../styles/images/flor-verde.png'
import EdificioSvg from './ECASvg'
import PlanosC from '../../planos/PlanosC'

// ECAPLANOS DSEKTOP
import PBA from '../../../styles/images/planos/PB_A-ECA.png'
import PBB from '../../../styles/images/planos/PB_B-ECA.png'
import N1CD from '../../../styles/images/planos/N1_D_ECA.png'
import N1C from '../../../styles/images/planos/N1_C_ECA.png'
import N2ERG from '../../../styles/images/planos/N2_E-RG_E_ECA.png'
import N2FRG from '../../../styles/images/planos/N2_F-RG_F-ECA.png'
// PLANOS MOVILE
import PBAM from '../../../styles/images/planosMovil/PB_A_ECA_MOBILE.png'
import PBBM from '../../../styles/images/planosMovil/PB_B_ECA_MOBILE.png'
import N1CDM from '../../../styles/images/planosMovil/N1_D_ECA_MOBILE.png'
import N1CM from '../../../styles/images/planosMovil/N1_C_ECA_MOBILE.png'
import N2ERGM from '../../../styles/images/planosMovil/N2_E-RG_E_ECA_MOBILE.png'
import N2FRGM from '../../../styles/images/planosMovil/N2_F-RG_F_ECA_MOBILE.png'

import mininaya from '../../../styles/images/mininaya/eca-ima.png'
import minitorre from '../../../styles/images/mini-torre-verde.png'

class ECA extends React.Component {
  constructor(props) {
    super(props)
    this.setState({
      informacionEdificio: props.informacionEdificioEca,
    })
  }

  state = {
    plano: '',
    informacionEdificio: {},
  }

  handleClick = e => {
    if (e.target.id !== 'Capa_1') {
      this.setState({ plano: e.target.id })
      this.props.handlePlano()
      console.log(e.target.id)
    }
  }

  returnRender = () => {
    this.props.desactivarPlano()
    this.setState({ plano: '' })
  }

  determinarPlano = () => {
    switch (this.state.plano) {
      case 'EPBA':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1101}
            plano={PBA}
            planoM={PBAM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={minitorre}
          />
        )
      case 'EPBAT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1101}
            plano={PBA}
            planoM={PBAM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={minitorre}
          />
        )
      case 'EPBB':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1102}
            plano={PBB}
            planoM={PBBM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={minitorre}
          />
        )
      case 'EPBBT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1102}
            plano={PBB}
            planoM={PBBM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={minitorre}
          />
        )
      case 'EN1CD':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1201}
            plano={N1C}
            planoM={N1CM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={minitorre}
          />
        )
      case 'EN1CDT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1201}
            plano={N1C}
            planoM={N1CM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={minitorre}
          />
        )
      case 'EN1C':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1202}
            plano={N1CD}
            planoM={N1CDM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={minitorre}
          />
        )
      case 'EN1CT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1202}
            plano={N1C}
            planoM={N1CM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={minitorre}
          />
        )
      case 'EN2ERG':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1302}
            plano={N2ERG}
            planoM={N2ERGM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={minitorre}
          />
        )
      case 'EN2ERGT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1302}
            plano={N2ERG}
            planoM={N2ERGM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={minitorre}
          />
        )
      case 'EN2FRG':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1301}
            plano={N2FRG}
            planoM={N2FRGM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={minitorre}
          />
        )
      case 'EN2FRGT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1301}
            plano={N2FRG}
            planoM={N2FRGM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={minitorre}
          />
        )
      default:
        return <EdificioSvg onClick={this.handleClick} />
    }
  }

  render() {
    return <div className="edificio">{this.determinarPlano()}</div>
  }
}

export default ECA
