import React, { useState } from 'react'
import '../../../styles/mapa.css'

import PlanosC from '../../planos/PlanosC'
import EdificioSvg from './AURASvg'

// AURA desktop
import PBA1 from '../../../styles/images/planos/PB_A1-AURA.png'
import PBA from '../../../styles/images/planos/PB_A-AURA.png'
import N1B1 from '../../../styles/images/planos/N1_B1-AURA.png'
import N1B from '../../../styles/images/planos/N1_B-AURA.png'
import N2B2 from '../../../styles/images/planos/N2_B2-AURA.png'
import N2B3 from '../../../styles/images/planos/N2_B3-AURA.png'
import N3C1RG from '../../../styles/images/planos/N3_C1-RG_C1-AURA.png'
import N3CRG from '../../../styles/images/planos/N3_C-RG_C-AURA.png'

// AURA movil
import PBA1M from '../../../styles/images/planosMovil/PB_A1-AURA.png'
import PBAM from '../../../styles/images/planosMovil/PB_A-AURA.png'
import N1B1M from '../../../styles/images/planosMovil/N1_B1-AURA.png'
import N1BM from '../../../styles/images/planosMovil/N1_B-AURA.png'
import N2B2M from '../../../styles/images/planosMovil/N2_B2-AURA.png'
import N2B3M from '../../../styles/images/planosMovil/N2_B3-AURA.png'
import N3C1RGM from '../../../styles/images/planosMovil/N3_C1-RG_C1-AURA.png'
import N3CRGM from '../../../styles/images/planosMovil/N3_C-RG_C-AURA.png'

import mtl from '../../../styles/images/minitorre/cafe-IZQUIERDO.png'
import mtr from '../../../styles/images/minitorre/cafe-DERECHO.png'
import rosa from '../../../styles/images/flor-cafe.png'
import mininaya from '../../../styles/images/mininaya/qui-aura.png'

class AURA extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    plano: '',
  }

  handleClick = e => {
    if (e.target.id !== 'Capa_1') {
      this.setState({ plano: e.target.id })
      this.props.handlePlano()
      console.log(e.target.id)
    }
  }

  returnRender = () => {
    this.props.desactivarPlano()
    this.setState({ plano: '' })
  }

  determinarPlano = () => {
    switch (this.state.plano) {
      case 'PBA':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5101}
            plano={PBA}
            planoM={PBAM}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'PBAT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5101}
            plano={PBA}
            planoM={PBAM}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'PBA1':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5102}
            plano={PBA1}
            planoM={PBA1M}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'PBA1T':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5102}
            plano={PBA1}
            planoM={PBA1M}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'N1B':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5201}
            plano={N1B}
            planoM={N1BM}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'N1BT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5201}
            plano={N1B}
            planoM={N1BM}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'N1B1':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5202}
            plano={N1B1}
            planoM={N1B1M}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'N1B1T':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5202}
            plano={N1B1}
            planoM={N1B1M}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'N2B2':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5301}
            plano={N2B2}
            planoM={N2B2M}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'N2B2T':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5301}
            plano={N2B2}
            planoM={N2B2M}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'N2B3':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5302}
            plano={N2B3}
            planoM={N2B3M}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'N2B3T':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5302}
            plano={N2B3}
            planoM={N2B3M}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'N3C1RG':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5402}
            plano={N3C1RG}
            planoM={N3C1RGM}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'N3C1RGT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5402}
            plano={N3C1RG}
            planoM={N3C1RGM}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'N3CRG':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5401}
            plano={N3CRG}
            planoM={N3CRGM}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      case 'N3CRGT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R5401}
            plano={N3CRG}
            planoM={N3CRGM}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
          />
        )
      default:
        return <EdificioSvg onClick={this.handleClick} />
    }
  }

  render() {
    return <div> {this.determinarPlano()} </div>
  }
}

export default AURA
