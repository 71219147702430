import React, { useState } from 'react'
import '../../../styles/mapa.css'
import EdificioSvg from './AVESvg'
import PlanosC from '../../planos/PlanosC'
import rosa from '../../../styles/images/flor-verde.png'
// AVE PLANOS
import N2B2 from '../../../styles/images/planos/N2_B2-AVE.png'
import N2B3 from '../../../styles/images/planos/N2_B3-AVE.png'
import N3CRG from '../../../styles/images/planos/N3_C-RG_C-AVE.png'
import N3C1 from '../../../styles/images/planos/N3_C1-RG_C1-AVE.png'
import N1B from '../../../styles/images/planos/N1_B-AVE.png'
import N1B1 from '../../../styles/images/planos/N1_B1-AVE.png'
import PBA from '../../../styles/images/planos/PB_A-AVE.png'
import PBA1 from '../../../styles/images/planos/PB_A1-AVE.png'
//planos mobile
import N2B2M from '../../../styles/images/planosMovil/N2_B2_AVE_MOBILE.png'
import N2B3M from '../../../styles/images/planosMovil/N2_B3_AVE_MOBILE.png'
import N3CRGM from '../../../styles/images/planosMovil/N3_C-RG_C_AVE_MOBILE.png'
import N3C1M from '../../../styles/images/planosMovil/N3_C1-RG_C1_AVE_MOBILE.png'
import N1BM from '../../../styles/images/planosMovil/N1_B_AVE_MOBILE.png'
import N1B1M from '../../../styles/images/planosMovil/N1_B1_AVE_MOBILE.png'
import PBAM from '../../../styles/images/planosMovil/PB_A_AVE_MOBILE.png'
import PBA1M from '../../../styles/images/planosMovil/PB_A1_AVE_MOBILE.png'

import mtl from '../../../styles/images/minitorre/Ave-IZQUIERDA.png'
import mtr from '../../../styles/images/minitorre/Ave-DERECHO.png'
import mininaya from '../../../styles/images/mininaya/ave.png'

class AVE extends React.Component {
  constructor(props) {
    super(props)
    this.setState({
      informacionEdificio: props.informacionEdificio,
    })
  }

  state = {
    plano: '',
  }

  handleClick = e => {
    if (e.target.id !== 'Capa_1') {
      this.setState({ plano: e.target.id })
      this.props.handlePlano()
      console.log(e.target.id)
    }
  }

  returnRender = () => {
    this.props.desactivarPlano()
    this.setState({ plano: '' })
  }

  determinarPlano = () => {
    switch (this.state.plano) {
      case 'PBA':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3101}
            plano={PBA}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
            planoM={PBAM}
          />
        )
      case 'PBAT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3101}
            plano={PBA}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
            planoM={PBAM}
          />
        )
      case 'PBA1':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3102}
            plano={PBA1}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
            planoM={PBA1M}
          />
        )
      case 'PBA1T':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3102}
            plano={PBA1}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
            planoM={PBA1M}
          />
        )
      case 'N1B':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3201}
            plano={N1B}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
            planoM={N1BM}
          />
        )
      case 'N1BT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3201}
            plano={N1B}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
            planoM={N1BM}
          />
        )
      case 'N1B1':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3202}
            plano={N1B1}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
            planoM={N1B1M}
          />
        )
      case 'N1B1T':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3202}
            plano={N1B1}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
            planoM={N1B1M}
          />
        )
      case 'N2B2':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3301}
            plano={N2B2}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
            planoM={N2B2M}
          />
        )
      case 'N2B2T':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3301}
            plano={N2B2}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
            planoM={N2B2M}
          />
        )
      case 'N2B3':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3302}
            plano={N2B3}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
            planoM={N2B3M}
          />
        )
      case 'N2B3T':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3302}
            plano={N2B3}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
            planoM={N2B3M}
          />
        )
      case 'N3C1':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3402}
            plano={N3C1}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
            planoM={N3C1M}
          />
        )
      case 'N3C1T':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3402}
            plano={N3C1}
            minitorre={mtr}
            rosa={rosa}
            mininaya={mininaya}
            planoM={N3C1M}
          />
        )

      case 'N3CRG':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3401}
            plano={N3CRG}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
            planoM={N3CRGM}
          />
        )
      case 'N3CRGT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R3401}
            plano={N3CRG}
            minitorre={mtl}
            rosa={rosa}
            mininaya={mininaya}
            planoM={N3CRGM}
          />
        )
      default:
        return <EdificioSvg onClick={this.handleClick} />
    }
  }

  render() {
    return <div className="edificio">{this.determinarPlano()}</div>
  }
}

export default AVE
