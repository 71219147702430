import React, { useState } from 'react'
import Backarrow from '../Backarrow'

import franjaVerdeActuva from '../../styles/images/franja-verde-activa.png'
import AURA from './AURA/AURA'
import ECA from './ECA/ECA'
import IMA from './IMA/IMA'
import AVE from './AVE/AVE'
import QU from './QU/QU'
import YUA from './YUA/YUA'
import HAA from './HAA/HAA'
import NAI from './NAI/NAI'
import TECA from '../../styles/images/titulos/ECA.svg'
import TIMA from '../../styles/images/titulos/IMA.svg'

import planosInformacion from '../../info.json'

class Edificio extends React.Component {
  state = {
    planoActive: 0,
  }

  backgrounStyleUp = {
    backgroundImage: `url(${this.props.franja})`,
    position: 'relative',
  }

  backgrounStyleDown = {
    backgroundColor: `${this.props.colorFranjaInferior}`,
  }

  componentDidMount() {
    const franjaTag = document.getElementById('guideView')
    franjaTag.scrollIntoView(true)
  }

  componentDidUpdate() {
    const franjaTag = document.getElementById('guideView')
    franjaTag.scrollIntoView(true)
  }

  handlePlano = e => {
    console.log(e)
    this.setState({ planoActive: 1 })
  }

  desactivarPlano = () => {
    this.setState({ planoActive: 0 })
  }

  handlerClickArrow = e => {
    e.preventDefault()
    this.setState({ planoActive: 0 })
    this.props.backMap()
  }

  renderEdificio = () => {
    const { edificio } = this.props
    switch (edificio) {
      case 'IMA':
        return (
          <IMA
            handlePlano={this.handlePlano}
            desactivarPlano={this.desactivarPlano}
            informacionEdificio={planosInformacion.IMA}
            informacionEdificioEca={planosInformacion.ECA}
          />
        )
      case 'AVE':
        return (
          <AVE
            handlePlano={this.handlePlano}
            desactivarPlano={this.desactivarPlano}
            informacionEdificio={planosInformacion.AVE}
          />
        )
      case 'ECA':
        return (
          <IMA
            handlePlano={this.handlePlano}
            desactivarPlano={this.desactivarPlano}
            informacionEdificio={planosInformacion.IMA}
            informacionEdificioEca={planosInformacion.ECA}
          />
        )
      case 'HAA':
        return (
          <HAA
            handlePlano={this.handlePlano}
            desactivarPlano={this.desactivarPlano}
            informacionEdificio={planosInformacion.HAA}
          />
        )
      case 'NAI':
        return (
          <NAI
            handlePlano={this.handlePlano}
            desactivarPlano={this.desactivarPlano}
            informacionEdificio={planosInformacion.NAI}
          />
        )
      case 'QU':
        return (
          <QU
            handlePlano={this.handlePlano}
            desactivarPlano={this.desactivarPlano}
            informacionEdificio={planosInformacion.QUI}
          />
        )
      case 'YUA':
        return (
          <YUA
            handlePlano={this.handlePlano}
            desactivarPlano={this.desactivarPlano}
            informacionEdificio={planosInformacion.YUA}
          />
        )
      case 'AURA':
        return (
          <AURA
            handlePlano={this.handlePlano}
            desactivarPlano={this.desactivarPlano}
            informacionEdificio={planosInformacion.AURA}
          />
        )
      default:
        break
    }
  }

  printTitle = (title, alt) => {
    if (alt === 'ECA') {
      if (this.state.planoActive === 0) {
        return (
          <div>
            <Backarrow handlerClickArrow={this.handlerClickArrow} />
            <img src={TECA} alt="ECA" className="EIleft" />
            <img src={TIMA} alt="IMA" className="EIright" />
          </div>
        )
      }
      return (
        <div>
          <img src={TECA} alt="ECA" className="EIleft" />
          <img src={TIMA} alt="IMA" className="EIright" />
        </div>
      )
    }

    if (alt === 'IMA') {
      if (this.state.planoActive === 0) {
        return (
          <div>
            <Backarrow handlerClickArrow={this.handlerClickArrow} />
            <img src={TECA} alt="ECA" className="EIleft" />
            <img src={TIMA} alt="IMA" className="EIright" />
          </div>
        )
      }
      return (
        <div>
          <img src={TECA} alt="ECA" className="EIleft" />
          <img src={TIMA} alt="IMA" className="EIright" />
        </div>
      )
    }
    if (this.state.planoActive === 0) {
      return (
        <div>
          <Backarrow handlerClickArrow={this.handlerClickArrow} /> <img src={title} alt={alt} />
        </div>
      )
    }
    return (
      <div>
        <img src={title} alt={alt} />
      </div>
    )
  }

  render() {
    const { planoActive } = this.state
    const { titleActive } = this.props
    const { edificio } = this.props
    return (
      <div>
        <div className="viewPortguide" id="guideView"></div>
        <div className="cabecera">
          <div
            className={`franja ${this.state.planoActive ? 'increse' : ''}`}
            style={this.backgrounStyleUp}
            id="franjaTag"
          >
            {planoActive === 0 ? (
              <div>{this.printTitle(titleActive, edificio)}</div>
            ) : (
                this.printTitle(titleActive, edificio)
              )}
          </div>
          <div className={`info ${this.state.planoActive ? 'infoHide' : ''}`} style={this.backgrounStyleDown}>
            <h3>SELECCIONA UN PISO</h3>
          </div>
        </div>
        <div className="body">{this.renderEdificio()}</div>
      </div>
    )
  }
}

export default Edificio
