import React from 'react'
import que from '../../../styles/images/torres/QU/QUI.png'

const QUSvg = props => {
  const handleHoverRen = ed => {
    let render = null
    let text = null
    switch (ed) {
      case 'PBA':
        render = document.getElementById('PBA')
        text = document.getElementById('PBAT')
        break
      case 'N1B':
        render = document.getElementById('N1B')
        text = document.getElementById('N1BT')
        break
      case 'N2B2':
        render = document.getElementById('N2B2')
        text = document.getElementById('N2B2T')
        break
      case 'N3CRG':
        render = document.getElementById('N3CRG')
        text = document.getElementById('N3CRGT')
        break
      case 'PBA1':
        render = document.getElementById('PBA1')
        text = document.getElementById('PBA1T')
        break
      case 'N1B1':
        render = document.getElementById('N1B1')
        text = document.getElementById('N1B1T')
        break
      case 'N2B3':
        render = document.getElementById('N2B3')
        text = document.getElementById('N2B3T')
        break
      case 'N3C1RG':
        render = document.getElementById('N3C1RG')
        text = document.getElementById('N3C1RGT')
        break
      default:
        break
    }

    render.classList.add('activeRender')
    text.classList.add('activeText')
  }

  const handleRemoveRen = ed => {
    let render = null
    let text = null
    switch (ed) {
      case 'PBA':
        render = document.getElementById('PBA')
        text = document.getElementById('PBAT')
        break
      case 'N1B':
        render = document.getElementById('N1B')
        text = document.getElementById('N1BT')
        break
      case 'N2B2':
        render = document.getElementById('N2B2')
        text = document.getElementById('N2B2T')
        break
      case 'N3CRG':
        render = document.getElementById('N3CRG')
        text = document.getElementById('N3CRGT')
        break
      case 'PBA1':
        render = document.getElementById('PBA1')
        text = document.getElementById('PBA1T')
        break
      case 'N1B1':
        render = document.getElementById('N1B1')
        text = document.getElementById('N1B1T')
        break
      case 'N2B3':
        render = document.getElementById('N2B3')
        text = document.getElementById('N2B3T')
        break
      case 'N3C1RG':
        render = document.getElementById('N3C1RG')
        text = document.getElementById('N3C1RGT')
        break
      default:
        break
    }

    render.classList.remove('activeRender')
    text.classList.remove('activeText')
  }

  return (
    <div>
      <img src={que} className="torre-render" alt="QUE" />
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1306 734"
        xmlSpace="preserve"
        className="cafe"
        onClick={props.onClick}
      >
        <g id="QUI-HAA">
          <g id="QUI_x2C__AURA_x2C__YUA_x2C__HAA">
            <polygon
              className="st0"
              id="PBA1"
              onMouseOver={e => handleHoverRen('PBA1')}
              onMouseLeave={e => handleRemoveRen('PBA1')}
              points="995.7,397.2 1062.2,329.7 1060.2,374.6 1041.3,397.2 1041.5,400.9 994,455.2 991.1,458.4 
			989.2,492.9 932.6,562.2 790.8,535.8 773.6,549.1 823.8,559.8 823.8,562.2 823.8,565.9 828.1,565.5 828.1,570.8 823.8,570.8 
			821.9,624.7 828.1,624.7 758.7,704.1 499.3,636.3 499.3,631.6 506.8,624.7 504.9,576.2 504.9,565.9 508.9,565.9 508.9,567.9 
			554.8,535.8 554.8,476.1 671,500.1 671,508.1 743.9,524 767.9,504.6 767.9,492.9 797.7,468.3 935.7,496 994,433.4 		"
            />
            <polygon
              className="st0"
              id="N1B1"
              onMouseOver={e => handleHoverRen('N1B1')}
              onMouseLeave={e => handleRemoveRen('N1B1')}
              points="793.7,388.3 940.7,412.7 999,355.7 999,317.1 1062.2,267.7 1062.2,323 995.5,391.2 994,424.9 
			935.7,487.7 793.7,460.2 767.9,479.2 767.9,504.6 743.9,524 671,508.1 671,493.5 554.8,468.3 554.8,396.1 671,416.9 671,424.9 
			745.9,437.8 770.5,418.7 770.5,405.1 		"
            />
            <polygon
              className="st0"
              id="N2B3"
              onMouseOver={e => handleHoverRen('N2B3')}
              onMouseLeave={e => handleRemoveRen('N2B3')}
              points="796.3,306.2 945.8,326.9 1005.1,277.6 1005.1,250.2 1064.4,208.3 1062.2,267.7 999,317.1 999,348 
			940.7,405.1 793.7,380.1 770.5,396.1 770.5,405.1 770.5,418.7 745.9,437.8 671,424.9 671,408.2 554.1,388 554.1,312.9 672,331 
			672,337.1 748,348 773.6,331.8 773.6,319.9 		"
            />
            <polygon
              className="st0"
              id="N3C1RG"
              onMouseOver={e => handleHoverRen('N3C1RG')}
              onMouseLeave={e => handleRemoveRen('N3C1RG')}
              points="618,103.8 798.2,52 949.8,59.9 948,94.2 1004,97.9 1004,109 1080.7,115.1 1080.7,119 1112.1,120.1 
			1112.1,126.2 1098.2,135.9 1098.2,183.5 1098.2,199.8 1064.4,224.2 1064.4,208.3 1005.1,250.2 1005.1,270.1 944.1,319 
			796.3,298.5 773.6,310.8 773.6,331.8 748,348 672,337.1 672,321.9 554.1,303.9 554.1,224.2 556.2,224.2 556.2,218 618,191.3 		"
            />
            <polygon
              className="st0"
              id="PBA"
              onMouseOver={e => handleHoverRen('PBA')}
              onMouseLeave={e => handleRemoveRen('PBA')}
              points="548,474.9 549.1,539.8 546.8,541.4 479,527.1 479,540.7 484.8,541.4 484.8,545.5 394,616.2 
			188.2,556.5 187.2,548.5 194,544.7 189.4,490.6 287.2,444.3 407.8,471.1 407.7,453.7 423.6,456 432.7,450.4 		"
            />
            <polygon
              className="st0"
              id="N1B"
              onMouseOver={e => handleHoverRen('N1B')}
              onMouseLeave={e => handleRemoveRen('N1B')}
              points="545.9,394.1 546.8,466.1 432.7,444.3 432.7,450.4 423,455.9 363.4,444.3 363.4,402.9 389.8,391.1 
			347.1,383.2 347.1,377.2 350.6,375.3 350.6,370.2 358.3,367.5 419,378 428.6,372.8 		"
            />
            <polygon
              className="st0"
              id="N2B2"
              onMouseOver={e => handleHoverRen('N2B2')}
              onMouseLeave={e => handleRemoveRen('N2B2')}
              points="424.9,292 544.1,310.9 545.1,385 429,365.2 429,372.8 419,378 358.3,367.5 357.1,325.2 374.6,317.6 
			343,313.1 343,305.9 343,302.1 372.8,289.8 414.3,295.7 		"
            />
            <polygon
              className="st0"
              id="N3CRG"
              onMouseOver={e => handleHoverRen('N3CRG')}
              onMouseLeave={e => handleRemoveRen('N3CRG')}
              points="543.2,224 543.8,302.6 427.1,284.9 423.3,286.2 423.3,291.7 414.3,295.7 352.4,286.2 352.4,257.7 
			352.4,243.3 352.4,242.2 337.9,241.3 337.9,200.1 389.8,184.5 389.8,172.5 333.7,167.3 333.7,160.3 397.8,142.6 397.8,139.1 
			493.8,113.7 493.2,104.1 471.9,102.2 472,89.2 493.8,82.9 493.8,77.8 554.1,66 554.1,63.1 652.7,40.2 786.2,50.8 611.1,101.9 
			611.3,188.9 542.8,217.8 		"
            />
            <text
              transform="matrix(1 0 0 1 266.33 516.0969)"
              className="st1 st2"
              id="PBAT"
              onMouseOver={e => handleHoverRen('PBA')}
              onMouseLeave={e => handleRemoveRen('PBA')}
            >
              <tspan x="0" y="0" id="PBA">
                UNIDAD 4101
              </tspan>
              <tspan x="28.9" y="21.6" id="PBA" className="sold">
                {/* 492 M2 */}
                Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 387.3731 413.0086)"
              className="st1 st2"
              id="N1BT"
              onMouseOver={e => handleHoverRen('N1B')}
              onMouseLeave={e => handleRemoveRen('N1B')}
            >
              <tspan x="0" y="0" id="N1B">
                UNIDAD 4201
              </tspan>
              <tspan x="28.9" y="21.6" id="N1B" className="sold">
		      423 m2
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 392.759 223.7337)"
              className="st1 st2"
              id="N3CRGT"
              onMouseOver={e => handleHoverRen('N3CRG')}
              onMouseLeave={e => handleRemoveRen('N3CRG')}
            >
              <tspan x="0" y="0" id="N3CRG">
                UNIDAD 4401
              </tspan>
              <tspan x="28.9" y="21.6" id="N3CRG" className="sold">
		      {/*546 M2*/}
			Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 604.8262 587.9111)"
              className="st1 st2"
              id="PBA1T"
              onMouseOver={e => handleHoverRen('PBA1')}
              onMouseLeave={e => handleRemoveRen('PBA1')}
            >
              <tspan x="0" y="0" id="PBA1">
                UNIDAD 4102
              </tspan>
              <tspan x="28.9" y="21.6" id="PBA1" className="sold">
		      {/*492 M2*/}
			Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 642.1604 460.1697)"
              className="st1 st2"
              id="N1B1T"
              onMouseOver={e => handleHoverRen('N1B1')}
              onMouseLeave={e => handleRemoveRen('N1B1')}
            >
              <tspan x="0" y="0" id="N1B1">
                UNIDAD 4202
              </tspan>
              <tspan x="28.9" y="21.6" id="N1B1"  className="sold">
		      {/*423 m2*/}
			Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 660.4073 236.385)"
              className="st1 st2"
              id="N3C1RGT"
              onMouseOver={e => handleHoverRen('N3C1RG')}
              onMouseLeave={e => handleRemoveRen('N3C1RG')}
            >
              <tspan x="0" y="0" id="N3C1RG">
                UNIDAD 4402
              </tspan>
              <tspan x="28.9" y="21.6" id="N3C1RG" className="sold">
		      {/*546 M2*/}
		Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 379.8995 333.1125)"
              className="st1 st2"
              id="N2B2T"
              onMouseOver={e => handleHoverRen('N2B2')}
              onMouseLeave={e => handleRemoveRen('N2B2')}
            >
              <tspan x="0" y="0" id="N2B2">
                UNIDAD 4301
              </tspan>
              <tspan x="28.9" y="21.6" id="N2B2" class="sold">
		      {/*423 m2*/}
			Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 664.6868 367.2736)"
              className="st1 st2"
              id="N2B3T"
              onMouseOver={e => handleHoverRen('N2B3')}
              onMouseLeave={e => handleRemoveRen('N2B3')}
            >
              <tspan x="0" y="0" id="N2B3">
                UNIDAD 4302
              </tspan>
              <tspan x="28.9" y="21.6" id="N2B3" className="sold">
		      {/*423 m2*/}
			Vendida
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    </div>
  )
}
export default QUSvg
