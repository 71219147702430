import React, { useState, useRef } from 'react'
import '../../styles/planos.min.css'
import { TweenMax, Power3 } from 'gsap'
import ButtonClose from '../ButtonClose'
import BackArrow from '../Backarrow'

function PlanosC(props) {
  let infoPanel = useRef(null)
  let content = useRef(null)
  const handlerClickArrow = e => {
    e.preventDefault()
    props.returnRender()
  }

  const { titulo, nivel, elementos, colorPlano, colorInfo } = props.caracteristicas
  const { mininaya } = props
  const listadoElementos = elementos.map(elm => <li>{elm}</li>)
  const closeHandle = e => {
    console.log(e.target)
    if (e.target.id === 'closeButton') {
      TweenMax.to(infoPanel, 0.8, {
        width: 500,
        padding: 30,
        display: 'block',
      })
    }
  }
  const handleClick = e => {
    if (e.target.id !== 'closeButton') {
      TweenMax.to(infoPanel, 0.8, {
        width: 0,
        padding: 0,
        display: 'none',
      })
    }
    console.log(e.target)
  }

  return (
    <div className="plano">
      <ButtonClose closeHandle={closeHandle} />
      <div className="plano__img" style={{ backgroundColor: `${colorPlano}` }} onClick={handleClick} id="plano__desktop">
        <div className="plano__img-plano">
          <img src={props.plano} alt="planos" />
        </div>
        <div className="plano__img-footer">
          <div>
            <img src={props.minitorre} alt="torre ECA" />
          </div>
          <div>
            <img src={props.rosa} alt="rosa de los vientos" />
          </div>
        </div>
      </div>
      <div className="plano__img" style={{ backgroundColor: `${colorPlano}` }} id="plano__movil">
        <div className="plano__img-plano">
          <img src={props.planoM} alt="planos" />
        </div>
        <div className="plano__img-footer">
          <div>
            <img src={props.minitorre} alt="torre ECA" />
          </div>
          <div>
            <img src={props.rosa} alt="rosa de los vientos" />
          </div>
        </div>
      </div>
      <div
        className="plano__info"
        ref={el => {
          infoPanel = el
        }}
        style={{ backgroundColor: `${colorInfo}` }}
      >
        <div
          className="padding"
          ref={el => {
            content = el
          }}
        >
          <h3 className="plano__title">
            {' '}
            <BackArrow handlerClickArrow={handlerClickArrow} />
            {titulo}
          </h3>
          <h4 className="plano__subtitle">{nivel}</h4>

          <ul className="plano__list">{listadoElementos}</ul>
          <p className="plano__parrafo">Ubicación en NAYA:</p>
          <img src={mininaya} alt="" />
        </div>
      </div>
    </div>
  )
}

export default PlanosC
