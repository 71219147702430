import React, { useState } from 'react'
import '../../../styles/mapa.css'
import ima from '../../../styles/images/torres/IMA/IMA.png'
import PlanosC from '../../planos/PlanosC'
import EdificioSvg from './IMASvg'
import rosa from '../../../styles/images/flor-verde.png'

// IMA plano
import N2E1 from '../../../styles/images/planos/N2_E1-RG_E1-IMA.png'
import N2F1 from '../../../styles/images/planos/N2_F1-RG_F1-IMA.png'
import N1C1 from '../../../styles/images/planos/N1_C1-IMA.png'
import PBA from '../../../styles/images/planos/PB_A1-IMA.png'
import PBB from '../../../styles/images/planos/PB_B1-IMA.png'
import N1D1 from '../../../styles/images/planos/N1_D1-IMA.png'

// IMA PLANO Movile
import N2E1M from '../../../styles/images/planosMovil/N2_E1-RG_E1_IMA_MOBILE.png'
import N2F1M from '../../../styles/images/planosMovil/N2_F1-RG_F1_IMA_MOBILE.png'
import N1C1M from '../../../styles/images/planosMovil/N1_C1_IMA_MOBILE.png'
import PBAM from '../../../styles/images/planosMovil/PB_A1_IMA_MOBILE.png'
import PBBM from '../../../styles/images/planosMovil/PB_B1_IMA_MOBILE.png'
import N1D1M from '../../../styles/images/planosMovil/N1_D1_IMA_MOBILE.png'

// ECAPLANOS DSEKTOP
import EPBA from '../../../styles/images/planos/PB_A-ECA.png'
import EPBB from '../../../styles/images/planos/PB_B-ECA.png'
import EN1CD from '../../../styles/images/planos/N1_D_ECA.png'
import EN1C from '../../../styles/images/planos/N1_C_ECA.png'
import EN2ERG from '../../../styles/images/planos/N2_E-RG_E_ECA.png'
import EN2FRG from '../../../styles/images/planos/N2_F-RG_F-ECA.png'
// PLANOS MOVILE
import EPBAM from '../../../styles/images/planosMovil/PB_A_ECA_MOBILE.png'
import EPBBM from '../../../styles/images/planosMovil/PB_B_ECA_MOBILE.png'
import EN1CDM from '../../../styles/images/planosMovil/N1_D_ECA_MOBILE.png'
import EN1CM from '../../../styles/images/planosMovil/N1_C_ECA_MOBILE.png'
import EN2ERGM from '../../../styles/images/planosMovil/N2_E-RG_E_ECA_MOBILE.png'
import EN2FRGM from '../../../styles/images/planosMovil/N2_F-RG_F_ECA_MOBILE.png'

import emtl from '../../../styles/images/minitorre/ECA-IZQUIERDO.png'
import emtr from '../../../styles/images/minitorre/ECA-DERECHO.png'

import imtl from '../../../styles/images/minitorre/Ima-IZQUIERDO.png'
import imtr from '../../../styles/images/minitorre/Ima-derecho.png'
import mininaya from '../../../styles/images/mininaya/eca-ima.png'

class IMA extends React.Component {
  constructor(props) {
    super(props)
    this.setState({
      informacionEdificio: props.informacionEdificio,
    })
  }

  state = {
    plano: '',
    informacionEdificio: {},
  }

  handleClick = e => {
    if (e.target.id !== 'Capa_1') {
      this.setState({ plano: e.target.id })
      this.props.handlePlano()
      console.log(e.target.id)
    }
  }

  returnRender = () => {
    this.props.desactivarPlano()
    this.setState({ plano: '' })
  }

  determinarPlano = () => {
    switch (this.state.plano) {
      case 'Ipbb':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R2101}
            plano={PBB}
            planoM={PBBM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={imtl}
          />
        )
      case 'Ipbbt':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R2101}
            plano={PBB}
            planoM={PBBM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={imtl}
          />
        )
      case 'Ipba':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R2102}
            plano={PBA}
            planoM={PBAM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={imtr}
          />
        )
      case 'Ipbat':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R2102}
            plano={PBA}
            planoM={PBAM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={imtr}
          />
        )
      case 'Inunoc':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R2201}
            plano={N1D1}
            planoM={N1D1M}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={imtl}
          />
        )
      case 'Inunoct':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R2201}
            plano={N1D1}
            planoM={N1D1M}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={imtl}
          />
        )
      case 'Inunod':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R2202}
            plano={N1C1}
            planoM={N1C1M}

            rosa={rosa}
            mininaya={mininaya}
            minitorre={imtr}
          />
        )
      case 'Inunodt':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R2202}
            plano={N1C1}
            planoM={N1C1M}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={imtr}
          />
        )
      case 'Indoserg':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R2301}
            plano={N2F1}
            planoM={N2F1M}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={imtl}
          />
        )
      case 'Indosergt':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R2301}
            plano={N2F1}
            planoM={N2F1M}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={imtl}
          />
        )
      case 'Indosfrg':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R2302}

            plano={N2E1}
            planoM={N2E1M}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={imtr}
          />
        )
      case 'Indosfrgt':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificio.R2302}
            plano={N2E1}
            planoM={N2E1M}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={imtr}
          />
        )
      //  ECA
      case 'EPBA':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1101}
            plano={EPBA}
            planoM={EPBAM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={emtl}
          />
        )
      case 'EPBAT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1101}
            plano={EPBA}
            planoM={EPBAM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={emtl}
          />
        )
      case 'EPBB':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1102}
            plano={EPBB}
            planoM={EPBBM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={emtr}
          />
        )
      case 'EPBBT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1102}
            plano={EPBB}
            planoM={EPBBM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={emtr}
          />
        )
      case 'EN1CD':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1201}
            plano={EN1C}
            planoM={EN1CM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={emtl}
          />
        )
      case 'EN1CDT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1201}
            plano={EN1C}
            planoM={EN1CM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={emtl}
          />
        )
      case 'EN1C':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1202}
            plano={EN1CD}
            planoM={EN1CDM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={emtr}
          />
        )
      case 'EN1CT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1202}
            plano={EN1CD}
            planoM={EN1CDM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={emtr}
          />
        )
      case 'EN2ERG':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1302}
            plano={EN2FRG}
            planoM={EN2FRGM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={emtr}
          />
        )
      case 'EN2ERGT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1302}
            plano={EN2FRG}
            planoM={EN2FRGM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={emtr}
          />
        )
      case 'EN2FRG':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1301}

            plano={EN2ERG}
            planoM={EN2ERGM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={emtl}
          />
        )
      case 'EN2FRGT':
        return (
          <PlanosC
            returnRender={this.returnRender}
            caracteristicas={this.props.informacionEdificioEca.R1301}
            plano={EN2ERG}
            planoM={EN2ERGM}
            rosa={rosa}
            mininaya={mininaya}
            minitorre={emtl}
          />
        )
      default:
        return <EdificioSvg onClick={this.handleClick} />
    }
  }

  render() {
    return <div className="edificio">{this.determinarPlano()}</div>
  }
}

export default IMA
