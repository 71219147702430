import React from 'react'
import IMAA from '../../../styles/images/torres/IMA/IMA.png'

const IMASvg = props => {
  const handleHoverRen = ed => {
    let render = null
    let text = null
    switch (ed) {
      case 'Ipbb':
        render = document.getElementById('Ipbb')
        text = document.getElementById('Ipbbt')
        break
      case 'Inunoc':
        render = document.getElementById('Inunoc')
        text = document.getElementById('Inunoct')
        break
      case 'Indoserg':
        render = document.getElementById('Indoserg')
        text = document.getElementById('Indosergt')
        break
      case 'Ipba':
        render = document.getElementById('Ipba')
        text = document.getElementById('Ipbat')
        break
      case 'Inunod':
        render = document.getElementById('Inunod')
        text = document.getElementById('Inunodt')
        break
      case 'Indosfrg':
        render = document.getElementById('Indosfrg')
        text = document.getElementById('Indosfrgt')

        break
      case 'EN2FRG':
        render = document.getElementById('EN2FRG')
        text = document.getElementById('EN2FRGT')
        break
      case 'EN1C':
        render = document.getElementById('EN1C')
        text = document.getElementById('EN1CT')
        break
      case 'EPBB':
        render = document.getElementById('EPBB')
        text = document.getElementById('EPBBT')
        break
      case 'EN2ERG':
        render = document.getElementById('EN2ERG')
        text = document.getElementById('EN2ERGT')
        break
      case 'EN1CD':
        render = document.getElementById('EN1CD')
        text = document.getElementById('EN1CDT')

        break
      case 'EPBA':
        render = document.getElementById('EPBA')
        text = document.getElementById('EPBAT')

      default:
        break
    }

    render.classList.add('activeRender')
    text.classList.add('activeText')
  }

  const handleRemoveRen = ed => {
    let render = null
    let text = null
    switch (ed) {
      case 'Ipbb':
        render = document.getElementById('Ipbb')
        text = document.getElementById('Ipbbt')
        break
      case 'Inunoc':
        render = document.getElementById('Inunoc')
        text = document.getElementById('Inunoct')
        break
      case 'Indoserg':
        render = document.getElementById('Indoserg')
        text = document.getElementById('Indosergt')
        break
      case 'Ipba':
        render = document.getElementById('Ipba')
        text = document.getElementById('Ipbat')
        break
      case 'Inunod':
        render = document.getElementById('Inunod')
        text = document.getElementById('Inunodt')

        break
      case 'Indosfrg':
        render = document.getElementById('Indosfrg')
        text = document.getElementById('Indosfrgt')

        break
      case 'EN2FRG':
        render = document.getElementById('EN2FRG')
        text = document.getElementById('EN2FRGT')
        break
      case 'EN1C':
        render = document.getElementById('EN1C')
        text = document.getElementById('EN1CT')
        break
      case 'EPBB':
        render = document.getElementById('EPBB')
        text = document.getElementById('EPBBT')
        break
      case 'EN2ERG':
        render = document.getElementById('EN2ERG')
        text = document.getElementById('EN2ERGT')
        break
      case 'EN1CD':
        render = document.getElementById('EN1CD')
        text = document.getElementById('EN1CDT')

        break
      case 'EPBA':
        render = document.getElementById('EPBA')
        text = document.getElementById('EPBAT')
        break
      default:
        break
    }

    render.classList.remove('activeRender')
    text.classList.remove('activeText')
  }

  return (
    <div>
      <img src={IMAA} className="torre-render" />
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1306 734"
        xmlSpace="preserve"
        className="verde"
        onClick={props.onClick}
      >
        <g id="IMA_1_">
          <g id="IMA">
            <polygon
              className="st0"
              id="Ipba"
              onMouseOver={e => handleHoverRen('Ipba')}
              onMouseLeave={e => handleRemoveRen('Ipba')}
              points="843.7,516.3 843.6,588.7 859.7,593.4 852.3,600.7 852.1,604.8 853,604.8 851.1,651.6 843.8,660.1
			843.6,666.6 1077,722.6 1104.2,657.7 1093.4,655.1 1097.2,604.9 1101.1,605 1101.2,599.7 1098,599.5 1098.1,596.4 1222.7,619.5
			1241.5,551.3 1212.1,544.9 1212.3,537.4 1082.9,514 1080.6,546 1067.8,568.2 983,551.9 983.2,544.2 		"
            />
            <polygon
              className="st0"
              id="Inunod"
              onMouseOver={e => handleHoverRen('Inunod')}
              onMouseLeave={e => handleRemoveRen('Inunod')}
              points="845.1,508.5 848.1,434.2 986.9,458.2 986.9,464.6 1073,479.9 1086.3,458.4 1089,429 1239.8,456
			1256.4,410.3 1247.5,480.1 1229.9,532.8 1079.9,506.8 1077.3,545.5 1064.7,568.4 984.3,552.2 984.8,535.1 		"
            />
            <polygon
              className="st0"
              id="Indosfrg"
              onMouseOver={e => handleHoverRen('Indosfrg')}
              onMouseLeave={e => handleRemoveRen('Indosfrg')}
              points="849.1,426.5 852.1,348 886.5,314.3 891,227.9 1000.8,160.5 1169.2,173.2 1165.3,216.9 1277.8,226.7
			1270.2,298.6 1260,317 1272.7,317.4 1260.7,402.4 1241.3,447.1 1089.9,424 1086.3,458.2 1076,479.7 989.8,465.5 991.2,448.7 		"
            />
            <polygon
              className="st0"
              id="Ipbb"
              onMouseOver={e => handleHoverRen('Ipbb')}
              onMouseLeave={e => handleRemoveRen('Ipbb')}

              points="834.9,588.6 761.4,571.7 761.4,581.3 768,582.8 767.9,591.3 721.3,638.4 533,589.9 533,585.2
			540.2,579 539.9,534.7 537.1,534.7 537.1,530.8 594.4,491.1 691,510.9 690.9,503.9 614.3,489.6 613.9,428.8 644.9,434.1
			635.2,440.8 635,453.4 632,455 632,481.9 699.7,494.8 706.3,489 836.8,515 		"
            />
            <polygon
              className="st0"
              id="Inunoc"
              onMouseOver={e => handleHoverRen('Inunoc')}
              onMouseLeave={e => handleRemoveRen('Inunoc')}
              points="615.2,422 614.1,356.8 638.5,360.4 635,361.9 635,373.7 632.1,374.9 632.1,402.8 701,415 708,409.9
			840.1,433 838,506.8 706.4,480.7 706.1,488.9 699.7,494.6 632,481.7 631.9,454.9 635.1,453.3 635.2,440.7 654.1,427.8 		"
            />
            <polygon
              className="st0"
              id="Indoserg"
              onMouseOver={e => handleHoverRen('Indoserg')}
              onMouseLeave={e => handleRemoveRen('Indoserg')}
              points="614.1,355.2 613.1,288 613.1,271 730.3,217.3 714.1,217.3 714.1,213.1 843.6,152.9 995.9,165.2
			883.3,232.6 879.9,307.1 876.2,308.5 876.1,320 843.8,344.5 841,429.7 709.8,407.2 708.1,408.8 708,415 701.1,420.1 632.2,408.1
			632.2,380.1 634.8,378.9 634.9,367.1 650.6,359.3 		"
            />
            <text
              transform="matrix(1 0 0 1 619.713 560.6141)"
              className="st1 st2"
              id="Ipbbt"
              onMouseOver={e => handleHoverRen('Ipbb')}
              onMouseLeave={e => handleRemoveRen('Ipbb')}
            >
              <tspan x="0" y="0" id="Ipbbt">
                UNIDAD 2101
              </tspan>
              <tspan x="28.9" y="21.6" id="Ipbbt"  className="sold">
		      {/*350 M2*/}
			Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 690.7561 458.5258)"
              id="Inunoct"
              onMouseOver={e => handleHoverRen('Inunoc')}
              onMouseLeave={e => handleRemoveRen('Inunoc')}
              className="st1 st2"
            >
              <tspan x="0" y="0" id="Inunoct">
                UNIDAD 2201
              </tspan>
              <tspan x="28.9" y="21.6" id="Inunoct" className="sold">
                {/*308 M2*/}
                Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 696.142 308.2508)"
              className="st1 st2"
              id="Indosergt"
              onMouseOver={e => handleHoverRen('Indoserg')}
              onMouseLeave={e => handleRemoveRen('Indoserg')}
            >
              <tspan x="0" y="0" id="Indosergt">
                UNIDAD 2301
              </tspan>
              <tspan x="28.9" y="21.6" id="Indosergt"  className="sold">
		      {/*418 M2*/}
			Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 908.2092 622.4283)"
              className="st1 st2"
              id="Ipbat"
              className="st1 st2"
              onMouseOver={e => handleHoverRen('Ipba')}
              onMouseLeave={e => handleRemoveRen('Ipba')}
            >
              <tspan x="0" y="0" id="Ipbat" >
		      UNIDAD 2102
              </tspan>
              <tspan x="28.9" y="21.6" id="Ipbat" className="sold">
		      366 M2
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 935.5434 504.6869)"
              className="st1 st2"
              onMouseOver={e => handleHoverRen('Inunod')}
              onMouseLeave={e => handleRemoveRen('Inunod')}
              id="Inunodt"
            >
              <tspan x="0" y="0" id="Inunodt">
                UNIDAD 2202
              </tspan>
              <tspan x="28.9" y="21.6" id="Inunodt" className="sold">
                {/*325 M2*/}
                Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 953.7902 360.9021)"
              className="st1 st2"
              id="Indosfrgt"
              onMouseOver={e => handleHoverRen('Indosfrg')}
              onMouseLeave={e => handleRemoveRen('Indosfrg')}
            >
              <tspan x="0" y="0" id="ndosfrgt">
                UNIDAD 2302
              </tspan>
              <tspan x="28.9" y="21.6" id="ndosfrgt"  className="sold">
		      {/*434 M2*/}
			Vendida
              </tspan>
            </text>
          </g>
        </g>
        <g id="ECA_2_">
          <g id="ECA_3_">
            <polygon
              className="st0"
              id="EN2ERG"
              onMouseOver={e => handleHoverRen('EN2ERG')}
              onMouseLeave={e => handleRemoveRen('EN2ERG')}
              points="605.9,352.1 527,340.7 502,352.1 502,365.5 474.8,379.9 415.6,370.2 415.6,356 321.6,341.9
			318.2,275.9 372,253.3 369.1,176.8 558.3,128.1 679.9,137.9 679.9,175.6 773.8,183.5 716,211.8 715.8,216.5 722.8,216.1
			605.9,268.5 		"
            />
            <polygon
              className="st0"
              id="EN1C"
              onMouseOver={e => handleHoverRen('EN1C')}
              onMouseLeave={e => handleRemoveRen('EN1C')}
              points="605.9,359.3 606.8,424 529.6,410.3 504,424 504,438.3 477.2,454.1 418.9,443 418.9,429.2
      328.1,411.8 323.4,348.5 415.5,363.9 415.3,369.8 474.2,379.9 501.9,365.7 501.8,358.3 528,348.5 		"
            />
            <polygon
              className="st0"
              points="606.8,431.9 606.8,490.6 529.6,475.7 494,497.6 495,525.8 502.6,526.9 502.6,531.6 443.4,572.7
			286.2,533.7 286.2,529.4 292.7,525.8 290.1,482.7 287.8,482.6 288.1,478.4 329.9,457.1 329.1,419.1 419.1,436.1 418.9,442.9
      477.1,454.1 504.1,438.3 504,430.4 528.8,416.8 		"
              id="EPBB"
              onMouseOver={e => handleHoverRen('EPBB')}
              onMouseLeave={e => handleRemoveRen('EPBB')}
            />
            <polygon
              className="st0"
              id="EN2FRG"
              onMouseOver={e => handleHoverRen('EN2FRG')}
              onMouseLeave={e => handleRemoveRen('EN2FRG')}
              points="317.1,340.3 224.2,325.1 224.2,331.9 214.1,335.5 165.8,326.9 165.9,302.7 167.1,301.3 167.1,291.3
			169.7,290.1 168,290 167.9,284.4 169.2,284.5 169.2,258.3 208.9,246.4 209.1,232.6 160.7,226.6 160.7,220.6 207.2,208.4
			207.2,205.9 279,187.9 278.8,169.9 248,168.3 247.9,163.8 435.5,118.7 551.5,127.6 363.9,177 367.5,250.8 313.7,275.1 		"
            />
            <polygon
              className="st0"
              points="322,410.4 232.9,393.1 230.2,393.1 230.2,399.3 221.3,403.8 173,394.6 171.1,371.3 173.8,371.3
      172.8,359.3 185.7,354.1 174,353.1 173.6,346.9 176.5,346.9 176.5,334.5 188.4,331.1 213.6,335.2 225.3,331.1 318.2,347.6 		"
              id="EN1CD"
              onMouseOver={e => handleHoverRen('EN1CD')}
              onMouseLeave={e => handleRemoveRen('EN1CD')}

            />
            <polygon
              className="st0"
              points="323.4,418.4 325.3,458.8 300.3,471.4 264.1,464.8 264.1,474.4 270.4,475.4 270.2,480.7 203.4,513.9
			72.3,483 72.3,477.1 77.4,475.1 74.2,434.4 72.3,434.6 72.3,429.9 142.6,401.7 176.3,409.4 189.1,412 199.9,400 220.9,403.6
      230,399.1 		"
              id="EPBA"
              onMouseOver={e => handleHoverRen('EPBA')}
              onMouseLeave={e => handleRemoveRen('EPBA')}
            />
            <text
              transform="matrix(1 0 0 1 318.617 506.0602)"
              className="st1 st2"
              id="EPBBT"
              onMouseOver={e => handleHoverRen('EPBB')}
              onMouseLeave={e => handleRemoveRen('EPBB')}
            >
              <tspan x="0" y="0" id="EPBBT">
                UNIDAD 1102
              </tspan>
              <tspan x="28.9" y="21.6" id="EPBBT" class="sold">
		      {/* 349 M2 */}
		    Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 389.6602 403.9718)"
              className="st1 st2"

              id="EN1CT"
              onMouseOver={e => handleHoverRen('EN1C')}
              onMouseLeave={e => handleRemoveRen('EN1C')}
            >
              <tspan x="0" y="0" id="EN1CT">
                UNIDAD 1202
              </tspan>
              <tspan x="28.9" y="21.6" id="EN1CT" className="sold">
                {/* 308 M2 */}
                  Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 395.0461 253.6969)"
              className="st1 st2"
              id="EN2ERGT"
              onMouseOver={e => handleHoverRen('EN2ERG')}
              onMouseLeave={e => handleRemoveRen('EN2ERG')}
            >
              <tspan x="0" y="0" id="EN2ERGT">
                UNIDAD 1302
              </tspan>
              <tspan x="28.9" y="21.6" id="EN2ERGT" className="sold">
		  {/*418 M2*/}
		  Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 114.055 448.0319)"
              className="st1 st2"
              id="EPBAT"
              onMouseOver={e => handleHoverRen('EPBA')}
              onMouseLeave={e => handleRemoveRen('EPBA')}
            >
              <tspan x="0" y="0" id="EPBAT">
                UNIDAD 1101
              </tspan>
              <tspan x="28.9" y="21.6" id="EPBAT" className="sold">
		      {/*366 M2*/}
			Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 182.6663 367.0005)"
              className="st1 st2"
              id="EN1CDT"
              onMouseOver={e => handleHoverRen('EN1CD')}
              onMouseLeave={e => handleRemoveRen('EN1CD')}
            >
              <tspan x="0" y="0" id="EN1CDT">
                UNIDAD 1201
              </tspan>
              <tspan x="28.9" y="21.6" id="EN1CDT"  className="sold">
		      {/*325 M2*/}
			Vendida
              </tspan>
            </text>
            <text
              transform="matrix(1 0 0 1 221.1842 229.5129)"
              className="st1 st2"
              id="EN2FRGT"
              onMouseOver={e => handleHoverRen('EN2FRG')}
              onMouseLeave={e => handleRemoveRen('EN2FRG')}
            >
              <tspan x="0" y="0" id="EN2FRGT">
                UNIDAD 1301
              </tspan>
              <tspan x="28.9" y="21.6" id="EN2FRGT"  className="sold">
		      {/*434 M2*/}
			Vendida
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default IMASvg
