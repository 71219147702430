import React from 'react'
import eca from '../../../styles/images/torres/ECA.png'

const ECASvg = props => {
  const handleHoverRen = ed => {
    let render = null
    let text = null
    switch (ed) {
      case 'N2FRG':
        render = document.getElementById('N2FRG')
        text = document.getElementById('N2FRGT')
        break
      case 'N1C':
        render = document.getElementById('N1C')
        text = document.getElementById('N1CT')
        break
      case 'PBB':
        render = document.getElementById('PBB')
        text = document.getElementById('PBBT')
        break
      case 'N2ERG':
        render = document.getElementById('N2ERG')
        text = document.getElementById('N2ERGT')
        break
      case 'N1CD':
        render = document.getElementById('N1CD')
        text = document.getElementById('N1CDT')

        break
      case 'PBA':
        render = document.getElementById('PBA')
        text = document.getElementById('PBAT')

        break
      default:
        break
    }

    render.classList.add('activeRender')
    text.classList.add('activeText')
  }

  const handleRemoveRen = ed => {
    let render = null
    let text = null
    switch (ed) {
      case 'N2FRG':
        render = document.getElementById('N2FRG')
        text = document.getElementById('N2FRGT')
        break
      case 'N1C':
        render = document.getElementById('N1C')
        text = document.getElementById('N1CT')
        break
      case 'PBB':
        render = document.getElementById('PBB')
        text = document.getElementById('PBBT')
        break
      case 'N2ERG':
        render = document.getElementById('N2ERG')
        text = document.getElementById('N2ERGT')
        break
      case 'N1CD':
        render = document.getElementById('N1CD')
        text = document.getElementById('N1CDT')

        break
      case 'PBA':
        render = document.getElementById('PBA')
        text = document.getElementById('PBAT')

        break
      default:
        break
    }

    render.classList.remove('activeRender')
    text.classList.remove('activeText')
  }

  return (
    <div>
      <img src={eca} className="torre-render" />
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1306 734"
        xmlSpace="preserve"
        className="verde"
        onClick={props.onClick}
      >
        <g id="IMA_1_">
          <g id="IMA">
            <polygon
              className="st0"
              points="843.7,516.3 843.6,588.7 859.7,593.4 852.3,600.7 852.1,604.8 853,604.8 851.1,651.6 843.8,660.1 
			843.6,666.6 1077,722.6 1104.2,657.7 1093.4,655.1 1097.2,604.9 1101.1,605 1101.2,599.7 1098,599.5 1098.1,596.4 1222.7,619.5 
			1241.5,551.3 1212.1,544.9 1212.3,537.4 1082.9,514 1080.6,546 1067.8,568.2 983,551.9 983.2,544.2 		"
            />
            <polygon
              className="st0"
              points="845.1,508.5 848.1,434.2 986.9,458.2 986.9,464.6 1073,479.9 1086.3,458.4 1089,429 1239.8,456 
			1256.4,410.3 1247.5,480.1 1229.9,532.8 1079.9,506.8 1077.3,545.5 1064.7,568.4 984.3,552.2 984.8,535.1 		"
            />
            <polygon
              className="st0"
              points="849.1,426.5 852.1,348 886.5,314.3 891,227.9 1000.8,160.5 1169.2,173.2 1165.3,216.9 1277.8,226.7 
			1270.2,298.6 1260,317 1272.7,317.4 1260.7,402.4 1241.3,447.1 1089.9,424 1086.3,458.2 1076,479.7 989.8,465.5 991.2,448.7 		"
            />
            <polygon
              className="st0"
              points="834.9,588.6 761.4,571.7 761.4,581.3 768,582.8 767.9,591.3 721.3,638.4 533,589.9 533,585.2 
			540.2,579 539.9,534.7 537.1,534.7 537.1,530.8 594.4,491.1 691,510.9 690.9,503.9 614.3,489.6 613.9,428.8 644.9,434.1 
			635.2,440.8 635,453.4 632,455 632,481.9 699.7,494.8 706.3,489 836.8,515 		"
            />
            <polygon
              className="st0"
              points="615.2,422 614.1,356.8 638.5,360.4 635,361.9 635,373.7 632.1,374.9 632.1,402.8 701,415 708,409.9 
			840.1,433 838,506.8 706.4,480.7 706.1,488.9 699.7,494.6 632,481.7 631.9,454.9 635.1,453.3 635.2,440.7 654.1,427.8 		"
            />
            <polygon
              className="st0"
              points="614.1,355.2 613.1,288 613.1,271 730.3,217.3 714.1,217.3 714.1,213.1 843.6,152.9 995.9,165.2 
			883.3,232.6 879.9,307.1 876.2,308.5 876.1,320 843.8,344.5 841,429.7 709.8,407.2 708.1,408.8 708,415 701.1,420.1 632.2,408.1 
			632.2,380.1 634.8,378.9 634.9,367.1 650.6,359.3 		"
            />
            <text transform="matrix(1 0 0 1 617.8521 556.4897)" className="st1 st2">
              UNIDAD 1-102
            </text>
            <text transform="matrix(1 0 0 1 646.7522 578.0897)" className="st1 st2">
              349 M2
            </text>
            <text transform="matrix(1 0 0 1 688.8953 454.4015)" className="st1 st2">
              UNIDAD 1-102
            </text>
            <text transform="matrix(1 0 0 1 717.7953 476.0015)" className="st1 st2">
              349 M2
            </text>
            <text transform="matrix(1 0 0 1 694.2812 304.1265)" className="st1 st2">
              UNIDAD 1-102
            </text>
            <text transform="matrix(1 0 0 1 723.1812 325.7264)" className="st1 st2">
              349 M2
            </text>
            <text transform="matrix(1 0 0 1 906.3483 618.304)" className="st1 st2">
              UNIDAD 1-102
            </text>
            <text transform="matrix(1 0 0 1 935.2484 639.9039)" className="st1 st2">
              349 M2
            </text>
            <text transform="matrix(1 0 0 1 933.6825 500.5626)" className="st1 st2">
              UNIDAD 1-102
            </text>
            <text transform="matrix(1 0 0 1 962.5825 522.1626)" className="st1 st2">
              349 M2
            </text>
            <text transform="matrix(1 0 0 1 951.9293 356.7778)" className="st1 st2">
              UNIDAD 1-102
            </text>
            <text transform="matrix(1 0 0 1 980.8293 378.3778)" className="st1 st2">
              349 M2
            </text>
          </g>
        </g>
        <g id="ECA_2_">
          <g id="ECA_3_">
            <polygon
              className="st0"
              points="604.3,351.9 525.4,340.5 500.4,351.9 500.4,365.3 473.2,379.7 414,370 414,355.8 320,341.7 
			316.6,275.7 370.4,253.1 367.5,176.6 556.7,127.9 678.3,137.7 678.3,175.4 772.2,183.3 714.4,211.6 714.2,216.3 721.2,215.9 
			604.3,268.3 		"
            />
            <polygon
              className="st0"
              points="604.3,359.1 605.2,423.8 528,410.1 502.4,423.8 502.4,438.1 475.6,453.9 417.3,442.8 417.3,429 
			326.5,411.6 321.8,348.3 413.9,363.7 413.7,369.6 472.6,379.7 500.3,365.5 500.2,358.1 526.4,348.3 		"
            />
            <polygon
              className="st0"
              points="605.2,431.7 605.2,490.4 528,475.5 492.4,497.4 493.4,525.6 501,526.7 501,531.4 441.8,572.5 
			284.6,533.5 284.6,529.2 291.1,525.6 288.5,482.5 286.2,482.4 286.5,478.2 328.3,456.9 327.5,418.9 417.5,435.9 417.3,442.7 
			475.5,453.9 502.5,438.1 502.4,430.2 527.2,416.6 		"
            />
            <polygon
              className="st0"
              points="315.5,340.1 222.6,324.9 222.6,331.7 212.5,335.3 164.2,326.7 164.3,302.5 165.5,301.1 165.5,291.1 
			168.1,289.9 166.4,289.8 166.3,284.2 167.6,284.3 167.6,258.1 207.3,246.2 207.5,232.4 159.1,226.4 159.1,220.4 205.6,208.2 
			205.6,205.7 277.4,187.7 277.2,169.7 246.4,168.1 246.3,163.6 433.9,118.5 549.9,127.4 362.3,176.8 365.9,250.6 312.1,274.9 		"
            />
            <polygon
              className="st0"
              points="320.4,410.2 231.3,392.9 228.6,392.9 228.6,399.1 219.7,403.6 171.4,394.4 169.5,371.1 172.2,371.1 
			171.2,359.1 184.1,353.9 172.4,352.9 172,346.7 174.9,346.7 174.9,334.3 186.8,330.9 212,335 223.7,330.9 316.6,347.4 		"
            />
            <polygon
              className="st0"
              points="321.8,418.2 323.7,458.6 298.7,471.2 262.5,464.6 262.5,474.2 268.8,475.2 268.6,480.5 201.8,513.7 
			70.7,482.8 70.7,476.9 75.8,474.9 72.6,434.2 70.7,434.4 70.7,429.7 141,401.5 174.7,409.2 187.5,411.8 198.3,399.8 219.3,403.4 
			228.4,398.9 		"
            />
            <text transform="matrix(1 0 0 1 316.9748 505.8695)" className="st1 st2">
              UNIDAD 1-102
            </text>
            <text transform="matrix(1 0 0 1 345.8748 527.4695)" className="st1 st2">
              349 M2
            </text>
            <text transform="matrix(1 0 0 1 388.018 403.7811)" className="st1 st2">
              UNIDAD 1-102
            </text>
            <text transform="matrix(1 0 0 1 416.918 425.3811)" className="st1 st2">
              349 M2
            </text>
            <text transform="matrix(1 0 0 1 393.4039 253.5061)" className="st1 st2">
              UNIDAD 1-102
            </text>
            <text transform="matrix(1 0 0 1 422.3039 275.1061)" className="st1 st2">
              349 M2
            </text>
            <text transform="matrix(1 0 0 1 112.4128 447.8412)" className="st1 st2">
              UNIDAD 1-102
            </text>
            <text transform="matrix(1 0 0 1 141.3128 469.4412)" className="st1 st2">
              349 M2
            </text>
            <text transform="matrix(1 0 0 1 181.0241 366.8098)" className="st1 st2">
              UNIDAD 1-102
            </text>
            <text transform="matrix(1 0 0 1 209.9241 388.4098)" className="st1 st2">
              349 M2
            </text>
            <text transform="matrix(1 0 0 1 219.542 229.3221)" className="st1 st2">
              UNIDAD 1-102
            </text>
            <text transform="matrix(1 0 0 1 248.442 250.9221)" className="st1 st2">
              349 M2
            </text>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default ECASvg
