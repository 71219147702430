import React from 'react'

function ButtonClose(props) {
    return (
        <button id='closeButton' className="button-close" onClick={props.closeHandle}>
            X
        </button>
    )
}

export default ButtonClose
