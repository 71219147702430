import React, { useState } from 'react'
import '../styles/mapa.css'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import FCafe from '../styles/images/franja-cafe.png'
import FVerde from '../styles/images/franja-verde-activa.png'
import FAzul from '../styles/images/franja-azul.png'

// import $ from 'jQuery'
import panorama from '../styles/images/edificiosup.png'
import panoramaMovil from '../styles/images/edificiosupmovil.png'
import ec from '../styles/images/ecaima.png'
import im from '../styles/images/ecaima-sup.png'
import aura from '../styles/images/haarura.png'
import ave from '../styles/images/ave.png'
import nai from '../styles/images/nai.png'
import yuaqu from '../styles/images/yuaqu.png'
import qu from '../styles/images/qu.png'
import Edificio from './edificios/Edificio'

// titluos
import TECA from '../styles/images/titulos/ECA.svg'
import TIMA from '../styles/images/titulos/IMA.svg'
import TAURA from '../styles/images/titulos/AURA.svg'
import TAVE from '../styles/images/titulos/AVE.svg'
import TNAI from '../styles/images/titulos/NAI.svg'
import TYUA from '../styles/images/titulos/YUA.svg'
import TQU from '../styles/images/titulos/QUI.svg'
import THAA from '../styles/images/titulos/HAA.svg'

// titluos active
// import TAURAActive from '../styles/images/titulos/AURAActive.svg'
import TNAIActive from '../styles/images/titulos/NAIActive.svg'
// import TYUAActive from '../styles/images/titulos/YUAActive.svg'
// import TQUActive from '../styles/images/titulos/QUIActive.svg'
// import THAAActive from '../styles/images/titulos/HAAActive.svg'

gsap.registerPlugin(ScrollTrigger)

class RenderInicial extends React.Component {
  state = {
    activeEdificio: 0,
    edificio: '',
    franja: null,
    titleActive: null,
    viewport: 1000,
  }

  colorFranjaInferior = ''

  // determinarVierportEvery = this.determinarViewport.bind(this)

  constructor(props) {
    super(props)
    this.shadow = null
    this.quTecho = null
    this.ecaTecho = null
    this.imaTecho = null
    this.auraTecho = null
    this.aveTecho = null
    this.naiTecho = null
    this.yuaTecho = null
    this.quiTecho = null
    this.haaTecho = null

    this.trigger = null
  }

  componentDidMount() {
    setTimeout(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.trigger,
          scrub: false,
        },
      })
      tl.from(this.shadow, { opacity: 0, duration: 0.5 })
      tl.from(this.ecaTecho, { y: -50, opacity: 0, duration: 0.2 })
      tl.from(this.imaTecho, { y: -50, opacity: 0, duration: 0.2 })
      tl.from(this.quTecho, { y: -50, opacity: 0, duration: 0.2 })
      tl.from(this.aveTecho, { y: -50, opacity: 0, duration: 0.2 })
      tl.from(this.quiTecho, { y: -50, opacity: 0, duration: 0.2 })
      tl.from(this.auraTecho, { y: -50, opacity: 0, duration: 0.2 })
      tl.from(this.haaTecho, { y: -50, opacity: 0, duration: 0.2 })
      tl.from(this.yuaTecho, { y: -50, opacity: 0, duration: 0.2 })
      tl.from(this.naiTecho, { y: -50, opacity: 0, duration: 0.2 })
    }, 1000)
    // this.determinarViewport()
    // window.addEventListener('resize', this.determinarVierportEvery)
  }

  handleClick = e => {
    let franjaTemp = null
    let activeTitle = null

    switch (e.target.id) {
      case 'IMA':
        franjaTemp = FVerde
        activeTitle = TIMA
        this.colorFranjaInferior = '#04211D'
        break
      case 'AVE':
        franjaTemp = FVerde
        activeTitle = TAVE
        this.colorFranjaInferior = '#04211D'
        break
      case 'ECA':
        franjaTemp = FVerde
        activeTitle = TECA
        this.colorFranjaInferior = '#04211D'
        break
      case 'HAA':
        franjaTemp = FCafe
        activeTitle = THAA
        this.colorFranjaInferior = '#322315'
        break
      case 'NAI':
        franjaTemp = FAzul
        activeTitle = TNAIActive
        this.colorFranjaInferior = '#001E38'
        break
      case 'QU':
        franjaTemp = FCafe
        activeTitle = TQU
        this.colorFranjaInferior = '#322315'
        break
      case 'YUA':
        franjaTemp = FCafe
        activeTitle = TYUA
        this.colorFranjaInferior = '#322315'
        break
      case 'AURA':
        franjaTemp = FCafe
        activeTitle = TAURA
        this.colorFranjaInferior = '#322315'
        break
      default:
        break
    }
    this.setState({
      franja: franjaTemp,
      edificio: e.target.id,
      activeEdificio: 1,
      titleActive: activeTitle,
    })
  }

  backMap = () => {
    this.setState({ activeEdificio: 0, edificio: '' })
  }

  // determinarViewport() {
  //   console.log(this)
  //   this.setState({ viewport: window.screen.width })
  // }

  render() {
    if (this.state.activeEdificio === 0) {
      return (
        <div>
          <div className="cabecera">
            <div className="franja ">
              <h2>MASTER PLAN</h2>
            </div>
            <div className="info ">
              <h3>Da clic a una torre para conocer las residencias de NAYA</h3>
            </div>
          </div>
          <div className="body">
            <div className="render">
              <div className="shadow" ref={div => (this.shadow = div)}></div>
              <div className="techo" id="ec_techo" onClick={this.handleClick} ref={div => (this.ecaTecho = div)}>
                <img src={ec} alt="lista" className="techo-png" id="ECA" onClick={this.handleClick} />
                <img src={TECA} alt="lista" className="techo-title" id="ECA" onClick={this.handleClick} />
              </div>
              <div className="techo" id="im_techo" ref={div => (this.imaTecho = div)}>
                <img src={im} alt="lista" className="techo-png" id="IMA" onClick={this.handleClick} />
                <img src={TIMA} alt="lista" className="techo-title" id="IMA" onClick={this.handleClick} />
              </div>
              <div className="techo" id="aura_techo" ref={div => (this.auraTecho = div)}>
                <img src={aura} alt="lista" className="techo-png" id="AURA" onClick={this.handleClick} />
                <img src={TAURA} alt="lista" className="techo-title" id="AURA" onClick={this.handleClick} />
              </div>
              <div className="techo" id="ave_techo" ref={div => (this.aveTecho = div)}>
                <img src={ave} alt="lista" className="techo-png" id="AVE" onClick={this.handleClick} />
                <img src={TAVE} alt="lista" className="techo-title" id="AVE" onClick={this.handleClick} />
              </div>
              <div className="techo" id="ha_techo" ref={div => (this.haaTecho = div)}>
                <img src={aura} alt="lista" className="techo-png" id="HAA" onClick={this.handleClick} />
                <img src={THAA} alt="lista" className="techo-title" id="HAA" onClick={this.handleClick} />
              </div>
              <div className="techo" id="nai_techo" ref={div => (this.naiTecho = div)}>
                <img src={nai} alt="lista" className="techo-png" id="NAI" onClick={this.handleClick} />
                <img src={TNAI} alt="lista" className="techo-title" id="NAI" onClick={this.handleClick} />
              </div>
              <div className="techo" id="yuaqu_techo" ref={div => (this.yuaTecho = div)}>
                <img src={yuaqu} alt="lista" className="techo-png" id="YUA" onClick={this.handleClick} />
                <img src={TYUA} alt="lista" className="techo-title" id="YUA" onClick={this.handleClick} />
              </div>
              <div className="techo" id="qu_techo" ref={div => (this.quTecho = div)}>
                <img src={qu} alt="lista" className="techo-png" id="QU" onClick={this.handleClick} />
                <img src={TQU} alt="lista" className="techo-title" id="QU" onClick={this.handleClick} />
              </div>
              <img
                src={panorama}
                alt=""
                className="img-fluid"
                id="renderTechos"
              />
              <img
                src={panoramaMovil}
                alt=""
                className="img-fluid"
                id="renderTechosM"
              />
            </div>
          </div>
          <div ref={div => (this.trigger = div)} className="trigged"></div>
        </div>
      )
    }
    if (this.state.activeEdificio === 1) {
      return (
        <Edificio
          edificio={this.state.edificio}
          backMap={this.backMap}
          franja={this.state.franja}
          titleActive={this.state.titleActive}
          colorFranjaInferior={this.colorFranjaInferior}
        />
      )
    }
  }
}

export default RenderInicial
